import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Animated = ({ className, animationClasses = "animated tada", ...rest }) => {

    const [hover, setHover] = useState(false)

    return (<div 
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)} className={`${hover && animationClasses}`}>
                <img
                    {...rest}
                    className={`${className} `}
                />

    </div>)
}

Animated.propTypes = {

}

export default Animated
