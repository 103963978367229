import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout/Layout';
import Design from '../images/icons/ui_ux_icon.svg'
import Developer from '../images/icons/developer_icon.svg'
import Other from '../images/icons/other_icon.svg'
import Shadow from '../images/icons/shadow.svg'
import { Link } from 'gatsby';
import Animated from '../components/elements/Animated';
import SEO from '../components/SEO';

const Index = props => {
  return (
    <Layout>
    <SEO 
      title="Maker of things"
      description="Sam is a freelance developer and designer, a published author, an entrepreneur and ex-startup founder."
    />
      <section className="flex px-12 min-h-screen -mt-12 md:-mt-4">
        <div className="self-center mx-auto my-12">
          <div className="w-full">
            <div className="flex justify-center md:space-x-24 space-x-6 ">
              <Link to="/developer" className="animated slow infinite pulse ">
                <Animated className="transform -rotate-45  max-w-100 md:max-w-lg -ml-1 " alt="Sam Loyd | Freelancer About" src={Developer} />
              </Link>
              <Link to="/about" className="animated slow infinite pulse delay-02s "><Animated className="transform rotate-45 max-w-100 md:max-w-lg" alt="Sam Loyd | Freelancer Developer Icon" src={Other} /></Link>
            </div>
            <Link to="/designer">
              <Animated className="mx-auto transform -rotate-90 max-w-100 md:max-w-lg  animated slow infinite pulse delay-04s " alt="Sam Loyd | Freelancer Designer Icon" src={Design} />
            </Link>
          </div>
          <img className="mx-auto" src={Shadow} />
          <h1 className="font-heading text-center">Sam Loyd | Maker </h1>
        </div>
      </section>
    </Layout>
  )
}

Index.propTypes = {

}

export default Index
